import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';

const LoginFormStyled = makeStyles((theme) => ({
    test                  : {background: "hotpink", border: '1px solid red'},
    root                  : {
        position                        : 'absolute'
        , display                       : "flex"
        , width                         : '540px'
        , height                        : "100vh"
        , flexDirection                 : 'column'
        , background                    : 'rgba(17, 17, 22, 0.95);'
        , zIndex                        : 3
        , padding                       : 50
        , color                         : '#fff'
        , '& p, & span'                 : {
            fontFamily: 'Poppins'
        }
        , [theme.breakpoints.down('md')]: {
            width    : '100vw'
            , padding: 20
            , background                    : 'rgba(17, 17, 22);'
        }
    }
    , closeBtn            : {
        width                           : 65,
        height                          : 65,
        padding                         : 0
        , [theme.breakpoints.down('md')]: {
            width : 50,
            height: 50,
        }
    }
    , logoImage           : {
        marginTop                       : 20
        , marginLeft                    : '-20px'
        , marginBottom                  : 60
        , [theme.breakpoints.down('md')]: {
            marginTop     : 0
            , marginBottom: 0
        }
    }
    , userName            : {
        fontWeight  : 500
        , fontSize  : '1rem'
        , lineHeight: '1.5rem'
    }
    , info            : {
        fontWeight  : 500
        , fontSize  : '0.9rem'
        , lineHeight: '1.5rem'
        ,marginBottom:20
    }
    , inputField          : {
        background                                                   : '#cecece1f'
        , margin                                                     : '13px 0 18px 0'
        , borderRadius                                               : 5
        , paddingLeft : 10
        , '& .MuiOutlinedInput-root'                                 : {
            "&:hover"                                   : {
                color: theme.palette.primary.contrastText
            }
            , '&.Mui-focused fieldset'                  : {
                borderColor  : theme.palette.primary.contrastText
                , borderWidth: '1px'
            }
            , "&:hover .MuiOutlinedInput-notchedOutline": {
                border : "none"
                , color: theme.palette.primary.contrastText
            }

        }
        , '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: "none"
        }

    }
    , inputText           : {
        height                : 65
        , padding             : 0
        , fontSize            : "0.875rem"
        , color               : '#fff'
        , "&:-webkit-autofill": {
            transitionDelay  : "9999s"
            , WebkitBoxShadow: "0 0 0 100px rgba(0,0,0,0) inset"
        }
    }
    , placeholder         : {
        '&::placeholder': {
            color: '#ADADAD'
        }
    }
    , icon                : {
        color: '#fff'
    }
    , controlBox          : {
        display         : 'flex'
        , justifyContent: "space-between"
    }
    , rememberIcon        : {
        height: "22px"
    }
    , rememberLabel       : {
        width                     : 180
        , '& .MuiTypography-body1': {
            color       : "#5E6066"
            , fontWeight: 500
            , fontSize  : "0.875rem"
        }
    }
    , rememberLabelChecked: {
        width                     : 180
        , '& .MuiTypography-body1': {
            color       : theme.palette.primary.contrastText
            , fontWeight: 500
            , fontSize  : "0.875rem"
        }
    }
    , remainTimeBox       : {
        display         : 'flex'
        , alignItems    : 'center'
        , justifyContent: 'center'
        , '& > *'       : {
            margin: '0 5px'
        }
    }
    , loginBtn            : {
        height                          : 60
        , marginTop                     : theme.spacing(5)
        , [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1)
        }
    }
    , lineBox             : {
        display                         : 'flex'
        , height                        : 30
        , alignItems                    : 'center'
        , justifyContent                : 'center'
        , marginTop                     : theme.spacing(4)
        , marginBottom                  : theme.spacing(4)
        , '& > hr'                      : {
            display      : 'flex'
            , flex       : 1
            , margin     : 0
            , borderColor: '#322F38'
        }
        , '& > p'                       : {
            fontWeight  : 500
            , fontSize  : '1rem'
            , lineHeight: '1.5rem'
            , margin    : '0 20px'
        }
        , [theme.breakpoints.down('md')]: {
            marginTop     : theme.spacing(2)
            , marginBottom: theme.spacing(2)
        }
    }
    , subLoginGroup       : {
        display                   : "flex"
        , '& > button'            : {
            display                 : "flex"
            , flex                  : 1
            , borderRadius          : 0
            , background            : 'transparent'
            , border                : '1px solid #322F38'
            , borderRight           : 0
            , boxSizing             : 'border-box'
            , color                 : '#fff'
            , paddingTop            : theme.spacing(2)
            , paddingBottom         : theme.spacing(2)
            , '& > .MuiButton-label': {
                '& > svg'   : {
                    marginRight: theme.spacing(1)
                }
                , fontWeight: ' 500'
                , fontSize  : ' 0.813rem'
                , lineHeight: ' 1.25rem'
            }
        }
        , '& > button:hover'      : {
            background: '#85858524'
        }
        , '& > button:first-child': {
            borderTopLeftRadius     : 5
            , borderBottomLeftRadius: 5
        }
        , '& > button:last-child' : {
            borderTopRightRadius     : 5
            , borderBottomRightRadius: 5
            , borderRight            : '1px solid #322F38'
        }

    }
    , alert               : {
        height                                   : "3.5rem"
        , fontSize                               : "0.688rem"
        , [theme.breakpoints.between('sm', 'md')]: {
            width: '520px'
        }
        , [theme.breakpoints.down("xs")]         : {
            height                                        : "2.5rem"
            , fontSize                                    : "0.5rem"
            , minWidth                                    : '300px'
            , '& > .MuiAlert-root'                        : {
                fontSize    : "0.563rem"
                , height    : "2.5rem"
                , padding   : "0px 16px 0px 16px"
                , alignItems: "center"
            }
            , '& > .MuiAlert-standardError .MuiAlert-icon': {
                padding    : "0px"
                , alignSelf: "center"
            }
            , '& > .MuiAlert-standardInfo .MuiAlert-icon' : {
                padding    : "0px"
                , alignSelf: "center"
            }
        }
    }
    , bottomBox           : {
        display         : 'flex'
        , flex          : 1
        , flexDirection : 'column'
        , justifyContent: 'flex-end'
        , '& > :first-child': {
            marginTop: "70px"
            , maxWidth: 'fit-content'
            , textDecoration: 'underline'
            , textUnderlineOffset: '3px'
            , display: 'flex'
            , alignItems: 'center'
            , fontSize: 12
            , fontWeight: 500
            , fontFamily: 'Poppins'
            , '&:hover': {
                cursor: 'pointer'
            }
            , '& > svg': {
                marginRight: '4px'
            }
        }
    }
    , copyright           : {
        marginTop: 20
        , color  : "#9F9FA1"
        , ...theme.typography.subtitle2
    },


}));

function getLoginFormStyled() {
    const _loginFormStyled = LoginFormStyled();
    return _loginFormStyled;
}

export {getLoginFormStyled}