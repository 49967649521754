import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useTheme} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import {getLoginFormStyled} from "./LoginFormStyled";
import {ReactComponent as ArrowCloseIcon} from "../../images/gccs/arrow_close.svg";
import IconButton from "@material-ui/core/IconButton";
import {LogoImage} from "../../components/Logo";
import Typography from "@material-ui/core/Typography";
import {Button, InputAdornment, useMediaQuery} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import TextField from "@material-ui/core/TextField";
import "@fontsource/poppins";
import Checkbox from "@material-ui/core/Checkbox";
import {ReactComponent as UncheckedIcon} from "../../images/gccs/check_false.svg";
import {ReactComponent as CheckedIcon} from "../../images/gccs/check_true.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {ReactComponent as QrIcon} from "../../images/gccs/qr_icon.svg";
import {ReactComponent as OtpIcon} from "../../images/gccs/otp_icon.svg";
import {ReactComponent as TotpIcon} from "../../images/gccs/totp_icon.svg";
import {AppDownLink, ProgressAlert, StatusAlert} from "./LoginFunc";
import Copyright from "../../components/Copyright";
import LoginFunc from "./LoginFunc";
import api from "../../common/api";
import storage, {StorageKey as SK} from "../../common/storage";
import * as queryString from "querystring";
import QrPage from "./QrPage";
import {OtpType} from "../../common/types";
import OtpPage from "./OtpPage";



export default function LoginForm( props , loginPanelHandler ) {
    const classes = getLoginFormStyled();
    const theme = useTheme();
    const {t} = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const {location} = props;
    const query = queryString.parse(location.search);
    const [accessClientName, setAccessClientName] = useState(query.clientName);
    const [countDown, setCountDown] = useState(null);
    const [userKey, setUserKey] = useState('');
    const [errMsg, setErrMsg] = useState(null);
    const [rememberMe, setRememberMe] = useState(false);
    const [masterClientKey, setMasterClientKey] = useState(api.MASTER_CLIENT_KEY);
    const [progressMsg, setProgressMsg] = useState(null);
    const [otpOpen, setOtpOpen] = useState(false);
    const [otpUserKey, setOtpUserKey] = useState(false);
    const [qrData, setQrData] = useState({ open:false, qrId: '', qrUrl:'' });
    const [totpOpen, setTotpOpen] = useState(false);

    const isError = errMsg != null;
    const isProcess = (progressMsg != null) && (!isError);

    const _parentRef = useRef({});
    const _childRef = useRef({});

    useImperativeHandle(_parentRef , () => ({
        userKey : userKey,
        setUserKey : (value) => setUserKey(value),
        errMsg : errMsg,
        setErrMsg : (value) => setErrMsg(value),
        rememberMe : rememberMe,
        setRememberMe : (value) => setRememberMe(value),
        masterClientKey : masterClientKey,
        setMasterClientKey : (value) => setMasterClientKey(value),
        progressMsg : progressMsg,
        setProgressMsg : (value) => setProgressMsg(value),
        otpOpen : otpOpen,
        setOtpOpen : (value) => setOtpOpen(value),
        countDown : countDown,
        setCountDown : (value) => setCountDown(value),
        otpUserKey : otpUserKey,
        setOtpUserKey : (value) => setOtpUserKey(value),
        qrData : qrData,
        setQrData : (value) => setQrData(value),
        totpOpen : totpOpen,
        setTotpOpen : (value) => setTotpOpen(value),
    }));

    useEffect(() => {

        if (accessClientName !== undefined) {
            api.getAccessClientKey(accessClientName)
                .then((data) => {
                    setMasterClientKey(data);
                })
                .catch((err) => {
                    setErrMsg(err.rtMsg);
                })
        }

        const info = storage.getLocal(SK.LOGIN_INFO);
        if (info && info.rememberMe) {
            setUserKey(info.userKey);
            setRememberMe(info.rememberMe);
        }

    }, []);



    const onLogin = () => {
        _childRef.current?.onLogin();
    }

    return (
        <Box className={classes.root}>
            <LoginFunc ref={_childRef} parentRef={_parentRef}/>

            <IconButton className={classes.closeBtn} onClick={() => loginPanelHandler(false)}>
                <ArrowCloseIcon />
            </IconButton>

            <LogoImage className={classes.logoImage} width={300} height={'auto'}/>


            <Typography className={classes.userName}>{t('LoginPage.UserName')}</Typography>

            <TextField variant="outlined"
                       required
                       fullWidth
                       id="userKey"
                       name="userKey"
                       value={userKey}
                       onChange={_childRef.current?.onUserKeyChange}
                       color="primary"
                       placeholder={t('LoginPage.LoginNoID')}
                       className={classes.inputField}
                       inputProps={{className: classes.inputText}}
                       InputProps={{
                           /*startAdornment: (
                               <InputAdornment position="start">
                                   <EmailIcon fontSize="small" className={classes.icon} />
                               </InputAdornment>
                           ),*/
                           classes: {
                               adornedStart: classes.adornedStart,
                               input: classes.placeholder
                           }
                       }}
                       disabled={isProcess}
                       onKeyPress={(e) => {
                           e.key === 'Enter' && onLogin()
                       }}
            />

            <Box className={classes.controlBox}>
                <FormControlLabel
                    control={<Checkbox checked={rememberMe}
                                       icon={<UncheckedIcon className={classes.rememberIcon}/>}
                                       checkedIcon={<CheckedIcon className={classes.rememberIcon}/>}
                                       color='primary'
                                       onChange={_childRef.current?.onRememberMe}
                                       disabled={isProcess}
                    />}
                    className={(rememberMe === true ? classes.rememberLabelChecked : classes.rememberLabel)}
                    label={t('LoginPage.LabelRememberMe')}
                />

                {countDown !== null &&
                    <Box className={classes.remainTimeBox}>
                        <Typography variant='body1'>
                            {`${t('LoginPage.TimeRemain')} : ${countDown} (${t('LoginPage.Sec')})`}
                        </Typography>
                        <Button size='small' variant="contained" color="primary"
                                onClick={_childRef.current?.onCancel}
                        >
                            {t('LoginPage.BtnCancel')}
                        </Button>
                    </Box>
                }

            </Box>

            <Button fullWidth size='large'
                    variant="contained"
                    color="primary"
                    disabled={isProcess}
                    onClick={isProcess ? _childRef.current?.onCancel : onLogin}
                    className={classes.loginBtn}
            >
                {t('LoginPage.BtnLogin')}
            </Button>

            <Box className={classes.lineBox}>
                <hr />
                <Typography>{t('LoginPage.Or')}</Typography>
                <hr />
            </Box>


            <Box className={classes.subLoginGroup}>
                { !isMobile &&
                    <Button size='large' variant="contained"
                            disabled={isProcess}
                            onClick={isProcess ? _childRef.current?.onQrCancel : _childRef.current?.onQrLogin}
                    >
                        <QrIcon />
                        <Typography>QR</Typography>
                    </Button>
                }

                <Button
                    size='large'
                    variant="contained"
                    disabled={isProcess}
                    onClick={isProcess ? () => setOtpOpen(false) : _childRef.current?.onOTPLogin}
                >
                    <OtpIcon />
                    <Typography>OTP</Typography>
                </Button>

                <Button
                    size='large'
                    variant="contained"
                    disabled={isProcess}
                    onClick={isProcess ? () => setTotpOpen(false) : () => setTotpOpen(true)}
                >
                    <TotpIcon />
                    <Typography>TOTP</Typography>
                </Button>

            </Box>

            <Box mt={3} hidden={!isError} className={classes.alert}>
                <StatusAlert status={errMsg} onClose={_childRef.current?.onErrorHandler}/>
            </Box>

            <Box mt={3} hidden={!isProcess} className={classes.alert}>
                <ProgressAlert status={progressMsg} count={countDown}/>
            </Box>

            <Box className={classes.bottomBox}>
                <AppDownLink />
                <Copyright _clazz={classes.copyright} />
            </Box>

            <QrPage open={qrData?.open}
                    onClose={_childRef.current?.onQrCancel}
                    title={t('LoginPage.QrDialog.Title')}
                    qrId={qrData?.qrId}
                    qrUrl={qrData?.qrUrl}
                    onProgressMsg={setProgressMsg}
                    onErrMsg={setErrMsg}
                    handedUserKey={userKey}
            />
            <OtpPage otpType={OtpType.DeviceOTP}
                     handedUserKey={userKey}
                     open={otpOpen}
                     onClose={() => setOtpOpen(false)}
                     onSuccess={_childRef.current?.onOTPSuccess}
                     numInputs={6}
            />

            <OtpPage otpType={OtpType.DeviceTOTP}
                     handedUserKey={userKey}
                     open={totpOpen}
                     onClose={() => setTotpOpen(false)}
                     onSuccess={_childRef.current?.onOTPTokenSuccess}
                     numInputs={6}
            />
        </Box>
    )
}
