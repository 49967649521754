import React, {useEffect, useRef, useState} from 'react';
import {Box} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    container: {
        width:'calc(100vw - 120px) !important',
        height:'calc(100vh - 61px) !important',
        color:'#fff',
        [theme.breakpoints.down('md')] :{
            width:'initial !important',
            height:'calc(100vh - 52px) !important',
        }
    },
    root        : {
        display                       : 'flex',
        flex                          : 1,
        height                        : 'inherit',
        background                    : `url(https://resources.fnsvalue.co.kr/fnsv/images/sec3/bg.jpg) no-repeat`,
        [theme.breakpoints.down('md')]: {
            backgroundSize: 'cover'
        }
    },
    inner       : {
        display                         : 'flex'
        , margin                        : '0 auto'
        , alignItems                    : 'center'
        , [theme.breakpoints.down('md')]: {
            justifyContent : 'center'
            , flexDirection: 'column'
        }
    },
    detailBox   : {
        maxWidth                        : 800
        , zIndex                        : 1
        , '& > h2'                      : {
            fontSize    : 45
            , lineHeight: '67px'
            , fontWeight: 'bold'
        }
        , '& > p'                       : {
            fontSize    : 21
            , lineHeight: '31px'
        }
        , [theme.breakpoints.down('md')]: {
            textAlign : 'center'
            , '& > h2': {
                fontSize    : 32
                , lineHeight: '30px'
            }
            , '& > p' : {
                fontSize    : 13
                , lineHeight: '20px'
                , margin    : '13px 30px 30px'
            }
        }
    },
    itemBox     : {
        [theme.breakpoints.up('lg')]    : {
            marginTop     : 50
            , marginBottom: 50
            , '& > dl'    : {
                display             : 'flex'
                , alignItems        : 'baseline'
                , justifyContent    : 'center'
                , padding           : '0 0 5px 70px'
                , flexDirection     : 'column'
                , backgroundPosition: 'left'
                , marginTop         : 20
                , marginBottom      : 20
                , '& > dt'          : {
                    fontSize    : 14
                    , lineHeight: '21px'
                    , fontWeight: 500
                }
                , '& > dd'          : {
                    fontSize    : 15
                    , lineHeight: '22px'
                    , color     : '#A4ACC4'
                    , margin    : 0
                }
            }
        }
        , [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    mirc        : {background: 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec3/ico_lock.png) no-repeat 0 0'},
    otsk        : {background: 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec3/ico_shield.png) no-repeat 0 0'},
    mdv         : {background: 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec3/ico_box.png) no-repeat 0 0'},
    chain       : {background: 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec3/ico_union.png) no-repeat 0 0'},
    gccsDownload: {
        border        : '1px solid rgba(255, 255, 255, 0.3)',
        boxSizing     : 'border-box',
        borderRadius  : '21px',
        padding       : '10px 50px',
        textDecoration: 'none',
        color         : '#fff',
    },
    mediaBox    : {
        display               : 'flex'
        , flex                : 1
        , justifyContent      : 'center'
        , width               : 661
        , background          : 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec3/bg_circle.png) no-repeat'
        , position            : 'relative'
        , right               : '-100%'
        , opacity             : 0
        , '-webkit-transition': 'all 1s 100ms linear'
        , transition          : 'all 1s 100ms linear'
        , '&.play'            : {
            right    : '0'
            , opacity: 1
        }
        , [theme.breakpoints.down('md')]: {
             flex              : '0.7'
            , backgroundSize    : '85%'
            , backgroundPosition: 'center'
        }
        , [theme.breakpoints.down('xs')]: {
            width               : 'initial'
        }
    },
    viewBox     : {
        display: 'flex'
        ,
        width: 369
        ,
        height: 751
        ,
        alignItems: 'center'
        ,
        justifyContent: 'center'
        ,
        background: 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec3/bg_phone.png) no-repeat'
        ,
        [theme.breakpoints.down('md')]: {
            width               : 'initial'
            , height            : 'initial'
            , backgroundSize    : '54%'
            , backgroundPosition: 'center'
            , '& > img'         : {
                width: '50%'
            }
        }
        ,[theme.breakpoints.down('xs')]: {
            backgroundSize    : '33%'
            , '& > img'         : {
                width: '30%'
            }
        }

    }

}));

const handleOpenPopup = (data) => {
    const popup = window.open(data, "FNSVALUE", "popup=yes");
}


export default function SolutionPage({name}) {

    const classes = useStyles();
    const [mediaPlay, setMediaPlay] = useState(false);
    const {t} = useTranslation();

    const _innerFlag = name !== undefined;

    console.log(classes);

    useEffect(() => {
        // setTimeout(()=>{ },500);
        setMediaPlay(prev => !prev)
    }, []);

    return (
        <section className={`${classes.root} ${_innerFlag && classes.container}`}>
            <Box className={classes.inner}>

                <Box className={classes.detailBox}>
                    <h2>{t('Fnsv.Solution.Title')}</h2>
                    <p>{t('Fnsv.Solution.Description')}</p>

                    <Box className={classes.itemBox}>
                        <dl className={classes.mirc}>
                            <dt>{t('Fnsv.Solution.Item.Mirc.Title')}</dt>
                            <dd>{t('Fnsv.Solution.Item.Mirc.Description')}</dd>
                        </dl>

                        <dl className={classes.otsk}>
                            <dt>{t('Fnsv.Solution.Item.Otsk.Title')}</dt>
                            <dd>{t('Fnsv.Solution.Item.Otsk.Description')}</dd>
                        </dl>

                        <dl className={classes.mdv}>
                            <dt>{t('Fnsv.Solution.Item.Mdv.Title')}</dt>
                            <dd>{t('Fnsv.Solution.Item.Mdv.Description')}</dd>
                        </dl>

                        <dl className={classes.chain}>
                            <dt>{t('Fnsv.Solution.Item.Chain.Title')}</dt>
                            <dd>{t('Fnsv.Solution.Item.Chain.Description')}</dd>
                        </dl>
                    </Box>

                    <a className={classes.gccsDownload}
                       href={'javascript:void(0);'} onClick={() => handleOpenPopup(t('Fnsv.Solution.IntroductionUrl'))}
                       download title={t('Fnsv.Solution.IntroductionBtn')}>
                        <span>{t('Fnsv.Solution.IntroductionBtn')}</span>
                    </a>

                </Box>

                <Box className={`${classes.mediaBox} ${mediaPlay ? 'play' : ''}`}>
                    <Box className={classes.viewBox}>
                        <img src="https://resources.fnsvalue.co.kr/fnsv/images/sec3/screen3.gif" alt="mobile view" width="338px"/>
                    </Box>
                </Box>

            </Box>

        </section>
    )
}