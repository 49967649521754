import React, {useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useTranslation} from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import {useStores} from '../../common/store';
import {AuthStatus, ErrCode, OtpType} from '../../common/types';
import {LogoImage} from '../../components/Logo';
import queryString from 'query-string';
import {
    ClickAwayListener,
    InputAdornment,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Slide,
    useMediaQuery
} from "@material-ui/core";
import {Fade, Grow} from '@mui/material';
import QrPage from './QrPage';
import OtpPage from './OtpPage';
import EmailIcon from '@material-ui/icons/Email';
import {ReactComponent as TotpIcon} from "../../images/gccs/totp_icon.svg";
import {ReactComponent as OtpIcon} from "../../images/gccs/otp_icon.svg";
import {ReactComponent as QrIcon} from "../../images/gccs/qr_icon.svg";
import {ReactComponent as GlobeIcon} from "../../images/gccs/globe-icon.svg";
import {ReactComponent as CheckedIcon} from "../../images/gccs/check_true.svg";
import {ReactComponent as UncheckedIcon} from "../../images/gccs/check_false.svg";
import {ReactComponent as ArrowCloseIcon} from "../../images/gccs/arrow_close.svg";
import Grid from "@material-ui/core/Grid";
import Copyright from "../../components/Copyright";
import IconButton from "@material-ui/core/IconButton";
import {useIdleTimer} from "react-idle-timer";
import api from "../../common/api";
import storage, {StorageKey as SK} from "../../common/storage";

const useStyles = makeStyles((theme) => ({

    // 전체 화면
    wholeScreen: {
        display: 'flex',
        flex: 1,
        maxWidth: '100vw',
        maxHeight: "100vh",
        padding: 0,
        justifyContent: 'center',
        backgroundColor: "#050407",
        overflow: 'auto',
        [theme.breakpoints.between('sm', 'md')]: {
            minHeight: "1000px"
        }
    },
    mainBg: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        height: '100vh',
        minHeight: '900px',
        background: '#050407',
        backgroundSize: 'cover',
        [theme.breakpoints.between('sm', 'md')]: {
            minWidth: "360px",
            width: "100vw",
            maxWidth: "100vw",
            minHeight: "600px",
        },
        [theme.breakpoints.down("xs")]: {
            minHeight: '640px',
            minWidth: "300px",
        }
    },
    topBtn: {
        width: '100vw',
        display: "flex",
        position: 'absolute',
        flexDirection: "row",
        justifyContent: "flex-end",
    },
    logoMain: {
        position: 'absolute',
        width: '261px',
        height: '87px',
        margin: '30px 0 0 30px'
    },
    loginShowBtn: {
        fontWeight: 700,
        fontSize: '0.75rem',
        margin: "40px 10px 0 0",
        width: '120px',
        height: "39px",
        borderRadius: "19.5px",
        backgroundColor: theme.palette.button.background,
        '&:hover': {
            backgroundColor: theme.palette.button.hover,
        },
        color: theme.palette.primary.contrastText,
    },
    arrow: {
        width: '62px',
        height: '62px',
        margin: '50px 0 0 0',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },

    leftPane: {
        position: 'absolute',
        display: "flex",
        justifyContent: "center",
        width: '540px',
        maxWidth: '540px',
        height: "100vh",
        minHeight: '900px',
        background: 'rgba(5, 4, 7, 0.3)',
        backgroundSize: 'cover',
        [theme.breakpoints.between('sm', 'md')]: {
            justifyContent: "center",
            width: "100vw",
            maxWidth: "100vw",
            minWidth: "360px",
            minHeight: "1000px",
            background: 'linear-gradient(180deg, rgba(29, 30, 35, 0) 0%, #18191E 65.1%)',
        },
        [theme.breakpoints.down("xs")]: {
            justifyContent: "center",
            width: "100vw",
            maxWidth: "100vw",
            minWidth: "300px",
            minHeight: "650px",
            background: 'linear-gradient(180deg, rgba(29, 30, 35, 0) 0%, #1D1E23 65.1%)',
        }
    },
    form: {
        flexDirection: "column",
        alignSelf: "flex-start",
        width: '420px',
        maxHeight: "100vh",
        [theme.breakpoints.between('sm', 'md')]: {
            alignSelf: "center",
            justifyContent: "center",
            width: "520px",
            minHeight: "1000px"
        },
        [theme.breakpoints.down("xs")]: {
            alignSelf: "center",
            width: '300px',
            minHeight: "650px"
        }
    },
    // 태블릿 화면의 배경 이미지
    leftPaneBg: {
        position: "absolute",
        minWidth: "360px",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${require('../../images/gccs/tablet_bg.png')})`,
        backgroundRepeat: "false",
        backgroundSize: "cover",
        opacity: 0.5,
        backgroundPositionY: "-122px",
    },
    rightPaneItem:{
        position: 'absolute',
        flex: 1,
        display: 'flex',
        minWidth: '100vw',
        height: '100vh',
        minHeight: '900px',
        backgroundSize: 'cover',
        transitionDuration: "700ms"
    },
    rightPane1: {
        backgroundImage: `url(${require('../../images/gccs/new_login_bg_1.png')})`,
    },
    rightPaneBSA1:{
        backgroundImage: `url(${require('../../images/bsa/new_login_bsa_bg_1.png')})`,
    },
    rightPane2: {
        backgroundImage: `url(${require('../../images/gccs/new_login_bg_2.png')})`,
    },

    // 오른쪽 배경
    introText: {
        '& > :first-child': {
            color: 'rgba(255, 255, 255, 0.6)',
            lineHeight: "20.7px",
            fontSize: '1.125rem',
            fontWeight: 500,
            marginBottom: '20px'
        },
        '& > :nth-child(2)': {
            color: theme.palette.primary.contrastText,
            lineHeight: "52px",
            fontSize: '2rem',
            fontWeight: 300
        },
        '& > :nth-child(3)': {
            color: theme.palette.primary.contrastText,
            lineHeight: "52px",
            fontSize: '2rem',
            fontWeight: 700
        }
    },
    introOn: {
        marginLeft: "620px",
        marginTop: "221px"
    },
    introOff: {
        marginLeft: "150px",
        marginTop: "250px"
    },

    // 배경 이미지 변경 버튼
    changeImgBars: {
        display: "flex",
        flexDirection: "row",
        height: 10,
        width: 120,
        margin: "75px 0 0 0",
        '&:hover': {
            cursor: 'pointer'
        }
    },
    whiteLine: {
        backgroundColor: theme.palette.primary.contrastText,
        height: "2px",
        width: "60px",
        marginTop: 5,
        borderWidth: 0,
    },
    greyLine: {
        opacity: 0.5,
    },

    // 언어 변경 버튼
    langAreaWidth: {
        width: 160,
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    },
    langBtn: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end",
        margin: "40px 40px 0 0",
        width: "120px",
        height: "39px",
        borderRadius: "19.5px",
        backgroundColor: "rgba(206, 206, 206, 0.2)",
        color: "#838790",
        ...theme.typography.subtitle2,
        [theme.breakpoints.between('sm', 'md')]: {
            height: "45px",
            width: "140px",
            margin: 0,
            color: "#8A8A8A",
            ...theme.typography.button,
            backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
        [theme.breakpoints.down("xs")]: {
            height: "27px",
            width: "85px",
            margin: 0,
            fontSize: "0.5rem",
            backgroundColor: "rgba(255, 255, 255, 0.1)"
        },
    },
    langMenuPaper: {
        borderRadius: "14.5px",
        marginTop: "5px",
        width: "120px",
        height: "70px",
        backgroundColor: 'rgba(206, 206, 206, 0.2)',
        [theme.breakpoints.between('sm', 'md')]: {
            width: "140px",
            height: "70px",
            backgroundColor: 'rgba(255, 255, 255, 0.1)'
        },
        [theme.breakpoints.down("xs")]: {
            width: "82px",
            height: "60px",
            fontSize: "0.563rem",
            backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }
    },
    langMenu: {
        ...theme.typography.subtitle2,
        color: "#838790",
        justifyContent: "center",
        minHeight: "16px",
        [theme.breakpoints.between('sm', 'md')]: {
            color: "#8A8A8A",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "3px 0 3px 0",
            fontSize: "0.5rem",
            minHeight: "14px",
        }
    },
    selectedLang: {
        backgroundColor: "transparent !important",
        color: theme.palette.primary.contrastText
    },
    globeSvg: {
        marginRight: "8px",
        [theme.breakpoints.down("xs")]: {
            marginRight: "2px",
            height: "14px"
        }
    },

    //가디언 로고
    logoImg: {
        alignSelf: "flex-start",
        margin: "38px 0px 50px -20px",
        [theme.breakpoints.between('sm', 'md')]: {
            marginBottom: "51px",
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: "14.09px",
        }
    },
    logoPng: {
        width: '277px',
        height: '93px',
        [theme.breakpoints.down("xs")]: {
            width: "205.45px",
            height: "68.91px"
        }
    },

    // 왼쪽 로그인 form
    userName: {
        color: theme.palette.primary.contrastText,
        fontSize: "1rem",
        fontWeight: 500,
        [theme.breakpoints.down('xs')]: {
            ...theme.typography.subtitle2,
        }
    },
    loginBtn: {
        marginTop: "30px",
        fontSize: '1rem',
        fontWeight: 700,
        height: '65px',
        width: "420px",
        borderRadius: "10px",
        backgroundColor: theme.palette.button.background,
        '&:hover': {
            backgroundColor: theme.palette.button.hover,
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: "520px",
            height: '80px',
            fontSize: '1.125rem',
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "15.03px",
            width: '300px',
            height: '46px',
            fontSize: '0.75rem',
        }
    },
    loginLabel: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    inputField: {
        height: '65px',
        width: '420px',
        margin: '13px 0 18px 0',
        '& .MuiOutlinedInput-root': {
            "&:hover": {
                color: theme.palette.primary.contrastText
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.contrastText,
                borderWidth: '1px'
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #fff",
                color: theme.palette.primary.contrastText
            }
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: "520px",
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: "3px",
            width: '300px',
            height: '46px',
        }
    },
    inputText: {
        height: "65px",
        padding: 0,
        fontSize: "0.875rem",
        "&:-webkit-autofill": {
            transitionDelay: "9999s",
            WebkitBoxShadow: "0 0 0 100px rgba(0,0,0,0) inset"
        },
        [theme.breakpoints.between('sm', 'md')]: {
            height: '80px',
            fontSize: '1rem',
        },
        [theme.breakpoints.down("xs")]: {
            height: '46px',
            fontSize: '0.688rem',
        },
    },
    adornedStart: {
        paddingLeft: "26px",
        color: "white",
        [theme.breakpoints.between('sm', 'md')]: {
            backgroundColor: "rgba(16, 16, 20, 0.6)"
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "15px",
        },
    },
    placeholder: {
        '&::placeholder': {
            color: '#ADADAD',
        },
    },

    rememberLabel: {
        width: '180px',
        '& .MuiTypography-body1': {
            color: "#5E6066",
            fontWeight: 500,
            fontSize: "0.875rem",
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: '1rem'
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: "0.688rem",
            }
        }
    },
    rememberLabelChecked: {
        width: '180px',
        '& .MuiTypography-body1': {
            color: theme.palette.primary.contrastText,
            fontWeight: 500,
            fontSize: "0.875rem",
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: '1rem'
            },
            [theme.breakpoints.down("xs")]: {
                fontSize: "0.688rem",
            }
        }
    },
    rememberIcon: {
        height: "22px",
        [theme.breakpoints.between('sm', 'md')]: {
            height: "25px",
        },
        [theme.breakpoints.down("xs")]: {
            height: "16px",
        }
    },

    orLine: {
        display: "grid",
        gridTemplateColumns: "1fr 60px 1fr",
        gridTemplateRows: "1fr",
        justifyContent: "center",
        height: "16px",
        width: "420px",
        margin: "50px 0px 50px 0px",
        [theme.breakpoints.between('sm', 'md')]: {
            width: "520px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "300px",
            gridTemplateColumns: "1fr 42px 1fr",
            margin: "30px 0px 30px 0px",
        },
    },
    orText: {
        display: "inline-grid",
        alignItems: "center",
        textAlign: "center",
        fontSize: "0.875rem",
        fontWeight: 500,
        color: theme.palette.primary.contrastText,
        width: "60px",
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: "1.125rem",
        },
        [theme.breakpoints.down("xs")]: {
            width: "42px",
            fontSize: "0.875rem",
        },
    },
    hrStyle: {
        display: "inline-grid",
        margin: 'auto 0px',
        width: "180px",
        border: '1px solid rgba(255, 255, 255, 0.05)',
        [theme.breakpoints.between('sm', 'md')]: {
            width: "230px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "129px"
        }
    },

    subLoginBtnBox: {
        display: 'flex',
        alignItems: "center",
        width: "420px",
        flex: 1,
        '& > :first-child': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        '& > :nth-child(2)': {
            borderRadius: 0,
            marginLeft: '-1px',
        },
        '& > :last-child': {
            marginLeft: '-1px',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0
        },
        '& > button': {
            display: 'flex',
            flex: 1,
            color: theme.palette.primary.contrastText,
            backgroundColor: 'transparent',
            border: '1px solid #322F38',
            boxSizing: 'border-box',
            borderRadius: 5,
            boxShadow: 'none',
            height: "45px",
            margin: "0",
            transition: 'none !important'
        },
        '& > button:hover': {
            backgroundColor: "transparent",
            border: "1px solid #fff",
            zIndex: 1
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: "520px",
            minWidth: "300px",
            '& > button': {
                height: "45px"
            }
        },
        [theme.breakpoints.down("xs")]: {
            width: "300px",
            minWidth: "300px",
            '& > :nth-child(2)': {
                borderRight: '1px solid #322F38',
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
            },
        }
    },
    subLoginBtnLabel: {
        justifyContent: "center",
        ...theme.typography.button,
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: "0.875rem",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.625rem",
            fontWeight: 700
        }
    },
    subLoginBtnIcon: {
        minWidth: "18.5px",
        minHeight: "18.5px",
        marginRight: "9.32px"
    },

    alert: {
        height: "3.5rem",
        width: '420px',
        fontSize: "0.688rem",
        [theme.breakpoints.between('sm', 'md')]: {
            width: '520px',
        },
        [theme.breakpoints.down("xs")]: {
            height: "2.5rem",
            fontSize: "0.5rem",
            width: '300px',
            '& > .MuiAlert-root': {
                fontSize: "0.563rem",
                height: "2.5rem",
                padding: "0px 16px 0px 16px",
                alignItems: "center"
            },
            '& > .MuiAlert-standardError .MuiAlert-icon': {
                padding: "0px",
                alignSelf: "center"
            },
            '& > .MuiAlert-standardInfo .MuiAlert-icon': {
                padding: "0px",
                alignSelf: "center"
            }
        }
    },
    description: {
        fontSize: '0.9375rem',
        lineHeight: '1.5625rem',
        color: theme.palette.text.secondary
    },
    progressAlert: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        '& > p': {
            fontSize: '0.9rem',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        '& > button': {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        [theme.breakpoints.down("xs")]: {
            '& > p': {
                fontSize: '0.5rem',
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            '& > button': {
                height: '20px',
                width: '25px',
                fontSize: '0.5rem',
            },
        },
    },

    downLinkBox: {
        display: 'flex',
        marginTop: "35px",
        [theme.breakpoints.between('sm', 'md')]: {
            marginTop: "0px",
            height: "100px",
            alignItems: "flex-end"
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "0px",
            height: "100px",
            alignItems: "flex-end"
        }
    },
    downLink: {
        display: 'flex',
        alignItems: 'center',
        padding: '3px 9px 0px 0px',
        boxSizing: 'border-box',
        lineHeight: '1.75',
        borderRadius: '4px',
        textDecoration: "none",
        '&:hover': {
            border: "none",
        },
        '& > img': {
            imageRendering: '-webkit-optimize-contrast'
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px"
        }
    },
    copyright: {
        color: "#9F9FA1",
        ...theme.typography.subtitle2,
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: "0.75rem",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.5rem",
        }
    },

}));

function LanguageSelector({clickLang}) {

    const classes = useStyles();
    const options = ['ENG', 'KOR'];
    const {t, i18n} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        index === 0 ? i18n.changeLanguage('en') : i18n.changeLanguage('ko');
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current?.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Grid container direction="column" alignItems="flex-end" className={classes.langAreaWidth}>
            <Grid item xs={12} ref={clickLang}>
                <Box variant="contained" size="small" className={classes.langMenu} ref={anchorRef}
                     aria-label="split button">
                    <Button variant="text" className={classes.langBtn} onClick={handleToggle}>
                        <GlobeIcon className={classes.globeSvg}/>
                        <div>{options[selectedIndex]}</div>
                    </Button>
                </Box>
                <Popper open={open} anchorEl={anchorRef.current} transition disablePortal placement="bottom-start">
                    {({TransitionProps, placement}) => (
                        <Grow {...TransitionProps} >
                            <Paper className={classes.langMenuPaper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                className={classes.langMenu}
                                                selected={index === selectedIndex}
                                                classes={{selected: classes.selectedLang}}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}

function StatusAlert({status, onClose}) {
    return (
        <Alert severity="error" onClose={onClose}>{status}</Alert>
    );
}

function ProgressAlert({status}) {
    return (
        <Alert severity="info">{status}</Alert>
    );
}

function ChangingBackground({show, clickBtn}) {

    const classes = useStyles();
    const {t} = useTranslation();
    const theme = useTheme();
    const [bgImage, setBgImage] = React.useState(0);

    useEffect(() => {

        // 배경 이미지 자동변경
        const interval = setInterval(() => {
            setBgImage(bgImage === 0 ? 1 : 0);
        }, 5000);
        return () => clearInterval(interval);

    }, null);

    const changeImage = () => {
        bgImage === 0 ? setBgImage(1) : setBgImage(0);
    }

    return (
        <Box className={`${classes.rightPaneItem} ${bgImage === 0 ? (theme.type === 'gccs' ? classes.rightPane1 : classes.rightPaneBSA1) : classes.rightPane2}`}>
            <Fade in={true} timeout={1500}>
                <Box className={`${classes.introText} ${show ? classes.introOn : classes.introOff}`}>
                    <Typography>Next Generation IT Authentication Security</Typography>
                    <Typography>We ensure your safety in the digital world through</Typography>
                    <Typography>
                        easy, fast and secure solution
                        {' BSA'}
                    </Typography>
                    <Box className={classes.changeImgBars} onClick={changeImage} ref={clickBtn}>
                        <hr className={bgImage === 0 ? classes.whiteLine : `${classes.greyLine} ${classes.whiteLine}`}/>
                        <hr className={bgImage === 0 ? `${classes.greyLine} ${classes.whiteLine}` : classes.whiteLine}/>
                    </Box>
                </Box>
            </Fade>
        </Box>
    )
}


export default function LoginMain(props) {
    const {ds} = useStores();
    const classes = useStyles();
    const theme = useTheme();
    const {t} = useTranslation();
    const [userKey, setUserKey] = React.useState('');
    const [otpUserKey, setOtpUserKey] = React.useState('');
    const [rememberMe, setRememberMe] = React.useState(false);
    const [errMsg, setErrMsg] = React.useState(null);
    const [progressMsg, setProgressMsg] = React.useState(null);
    const [countDown, setCountDown] = useState(null);
    let fncClearInterval = useRef(null);
    const [masterClientKey, setMasterClientKey] = useState(api.MASTER_CLIENT_KEY);

    const {location} = props;
    const query = queryString.parse(location.search);
    const [accessClientName, setAccessClientName] = useState(query.clientName);
    const [qrOpen, setQrOpen] = React.useState(false);

    const qrId = React.useRef(null);
    const qrUrl = React.useRef(null);

    const [otpOpen, setOtpOpen] = React.useState(false);
    const [totpOpen, setTotpOpen] = React.useState(false);

    const [loginShow, setLoginShow] = React.useState(false);

    const stompConnector = React.useRef(null);
    const isFinished = React.useRef(false);


    const onUserKeyChange = (event) => {
        setUserKey(event.target.value);
    };
    const onRememberMe = (event) => {
        setRememberMe(event.target.checked);
    };

    const customInterval = (time, onClose, timeoutMsg = null) => {
        let count = time;

        const id = setInterval(() => {
            count = count - 1;
            if (count) {
                setCountDown(count);
            } else {
                clear(true);
            }
        }, 1000);


        const clear = (isTimeout) => {

            console.log("clear event");

            if (id !== null) {
                if( stompConnector.current !== null ) {
                    console.log("stompConnector.current.deactivate();");
                    stompConnector.current.deactivate();
                }

                clearInterval(id);
                if (isTimeout && timeoutMsg) {
                    isFinished.current = true;

                    setErrMsg(timeoutMsg);
                }
                if (onClose) {
                    onClose();
                }
                setCountDown(null);
            }
        }

        return () => {
            clear(false)
        };
    }

    const onLogin = () => {

        // token login
        // api.setToken('ewogICJhbGciOiAiSFMzODQiCn0.ewogICJ1c2VyS2V5IjogImlyaW5hIiwKICAiY2xpZW50U2VxIjogMSwKICAiY2xpZW50S2V5IjogIjFkYWVjNzg1OTNhNjQzZTZiNTNjZTk4MDNkZWQ1OTE2IiwKICAidXNlclR5cGUiOiAiQ01NTUNMMDAxIiwKICAiYWNjZXNzaWJsZUNsaWVudCI6IHsKICAgICJmMDBkM2FiOTQzNzA0MmI4OTA3MTQ3YzdiZDg4NmVhMCI6ICJDTU1NQ0wwMDEiLAogICAgImQ0MjY2M2VjNDIwZTQyYjM4NmY5NTAwMmExMDg1NmZkIjogIkNNTU1DTDAwMSIsCiAgICAiMWRhZWM3ODU5M2E2NDNlNmI1M2NlOTgwM2RlZDU5MTYiOiAiQ01NTUNMMDAxIiwKICAgICJkOWI5MjU5ZDEyM2Y0ZjRhYTY4OTFhMDJjNGQ1Mzk4NyI6ICJDTU1NQ0wwMDEiLAogICAgImRhM2FjOWRiNTkzNTRjZmY5MDAyZTdmYWU2ODQ5ZDA2IjogIkNNTU1DTDAwMSIsCiAgICAiMWFmYzAzYWY2YTQ3NDNlYTgwNzBkNmQ5MDAyN2NiYjEiOiAiQ01NTUNMMDAxIiwKICAgICJkMjI3ZTI2OWEyOWQ0YjQ0YjMwZTI4YTljMjk2YmEyZiI6ICJDTU1NQ0wwMDEiLAogICAgImI4NzgyNWZiYjljNDQ3ZWU5ZTQ2YTVlMzlmMTA5ZDYwIjogIkNNTU1DTDAwMSIKICB9LAogICJleHAiOiAxNjQ1Mjg3NTM1Cn0.HS0NPfqxMI4l5YslXVTzXERnTSpN54tVuWcEofT_pNkDmpWWaEmbQQOLbOivudrS');
        // api.getMe()
        //     .then(data => {
        //         ds.setLogin(true);
        //         storage.setSession(SK.LOGIN_USER_INFO, {data});
        //     })
        //     .catch(err => {
        //         setErrMsg(err.rtMsg);
        //     });
        // return;

        setErrMsg(null);
        if (userKey == null || userKey.length <= 0) {
            setErrMsg(t('LoginPage.LoginNoID'));

            return;
        }
        if (rememberMe) {
            // 로그인정보를 저장한다.
            storage.setLocal(SK.LOGIN_INFO, {masterClientKey, userKey, rememberMe});
        } else {
            // 로그인정보를 삭제한다.
            storage.removeLocal(SK.LOGIN_INFO);
        }
        onLoginProcess(userKey);
    };

    const onLoginProcess = (p_userKey, isOtpAuth = false) => {

        isFinished.current = false;

        setProgressMsg(t('AuthStatus.RequestAuth'));

        api.requestAuth(masterClientKey, p_userKey, isOtpAuth)
            .then(res => {
                if (res.rtCode === ErrCode.RT_SUCCESS) {
                    const authTimeRemaining = res.data.authTimeRemaining;
                    const channelKey = res.data.channelKey;

                    // 타이머 시작
                    fncClearInterval.current = customInterval((authTimeRemaining / 1000) + 1,
                        null,
                        t('LoginPage.LoginTimeout'));

                    setProgressMsg(t('AuthStatus.CreateChannel'));
                    setProgressMsg(t('AuthStatus.SelectNodes'));

                    setProgressMsg(t('AuthStatus.CheckDevice'));

                    stompConnector.current = api.getStompWS(masterClientKey, p_userKey, channelKey)

                    stompConnector.current.onConnect = ( data ) => {

                        const subscribeCallBack = ( message ) => {
                            const data = JSON.parse(message.body);
                            const status = data.status;
                            const statusMsg = t('AuthStatus.' + status);

                            if( AuthStatus.isFinish( status ) ) {
                                subscribeStomp.unsubscribe();
                                setProgressMsg(null);

                                isFinished.current = true;

                                if (AuthStatus.isSuccess(status)) {

                                    if (isOtpAuth) {
                                        setOtpOpen(true);
                                    } else { /* END if (isOtpAuth) { */
                                        api.getAuthResult(masterClientKey, p_userKey, channelKey)
                                            .then(data => {
                                                if (data.rtCode === ErrCode.RT_SUCCESS) {

                                                    if(fncClearInterval.current !== null) {
                                                        fncClearInterval.current();
                                                    }

                                                    api.setToken(data.data);
                                                    api.getMe()
                                                        .then(data => {
                                                            ds.setLogin(true);
                                                            storage.setSession(SK.LOGIN_USER_INFO, {data});
                                                        })
                                                        .catch(err => {
                                                            setErrMsg(err.rtMsg);
                                                        });
                                                } else {
                                                    setErrMsg(api.getErrMsg(data.rtCode));
                                                    setProgressMsg(null);
                                                }
                                            })
                                            .catch(err => {
                                                setErrMsg(err.rtMsg);
                                            });
                                    }
                                } else { /* END if (AuthStatus.isSuccess(status)) { */
                                    setErrMsg(statusMsg);
                                    setProgressMsg(null);
                                    if ( fncClearInterval.current !== null ) {
                                        fncClearInterval.current();
                                    }
                                }
                            } else { /* END if( AuthStatus.isFinish( status ) ) {*/
                                setProgressMsg(statusMsg);
                            }
                        };

                        const subscribeStomp = stompConnector.current.subscribe('/user/queue', (message) => {
                            if( message.command === "MESSAGE" ) {
                                subscribeCallBack(message);
                            } else {
                                setErrMsg(t('AuthStatus.AuthFailed'));
                                setProgressMsg(null);
                            }

                        });


                    };

                    stompConnector.current.onWebSocketClose = () => {
                        console.log(" Close Web Socket Event Test");

                        if( !isFinished.current ) {
                            api.getAuthResult(masterClientKey, p_userKey, channelKey)
                                .then(data => {
                                    if (data.rtCode === ErrCode.RT_SUCCESS) {

                                        if(fncClearInterval.current !== null) {
                                            fncClearInterval.current();
                                        }

                                        api.setToken(data.data);
                                        api.getMe()
                                            .then(data => {
                                                ds.setLogin(true);
                                                storage.setSession(SK.LOGIN_USER_INFO, {data});
                                            })
                                            .catch(err => {
                                                setErrMsg(err.rtMsg);
                                            });
                                    } else {
                                        if( data.rtCode !== 2010 ) {
                                            setErrMsg(api.getErrMsg(data.rtCode));
                                            setProgressMsg(null);
                                        }
                                    }
                                })
                                .catch(err => {
                                    setErrMsg(err.rtMsg);
                                });
                        }

                    };

                    stompConnector.current.onDisconnect = () => {
                        console.log("close connect event test");
                    }


                } else {
                    setErrMsg(api.getErrMsg(res.rtCode));
                }
            })
            .catch(err => {
                if (fncClearInterval.current != null) {
                    fncClearInterval.current();
                }
                setErrMsg(err.rtMsg);
                setProgressMsg(null);
            });

    }

    const onCancel = () => {
        isFinished.current = true;

        api.deleteAuth(masterClientKey, otpUserKey ? otpUserKey : userKey)
            .then(data => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    setProgressMsg(null);
                    if (fncClearInterval.current !== null) { fncClearInterval.current(); }
                }
            }).catch(err => {
                setErrMsg(err.rtMsg);
            });
    }

    const onQrLogin = () => {
        api.getLoginQrUrl(masterClientKey)
            .then((data) => {
                qrId.current = data.qrId;
                qrUrl.current = data.qrUrl;
                setQrOpen(true);
            })
            .catch((err) => {
                setErrMsg(err.rtMsg);
            })
    }

    const onQrCancel = (isStop = false, userKey = null) => {
        setQrOpen(false);
        if( isStop ) { stopQrLogin(qrId.current, userKey, setProgressMsg, setErrMsg); }
    }

    useEffect(() => {

        if (accessClientName !== undefined) {
            api.getAccessClientKey(accessClientName)
                .then((data) => {
                    setMasterClientKey(data);
                })
                .catch((err) => {
                    setErrMsg(err.rtMsg);
                })
        }

        const info = storage.getLocal(SK.LOGIN_INFO);
        if (info && info.rememberMe) {
            setUserKey(info.userKey);
            setRememberMe(info.rememberMe);
        }

    }, []);

    const isError = errMsg != null;
    const isProcess = (progressMsg != null) && (!isError);

    const onErrorHandler = () => {
        setErrMsg(null);
        setProgressMsg(null);
    }

    const onOTPLogin = () => {
        setErrMsg(null);

        if( isProcess ) {
            setOtpOpen(false);
        } else {
            if (userKey) {
                onLoginProcess(userKey, true);
            } else {
                setOtpOpen(true);
            }
        }
    }

    const onOTPSuccess = ({otpUserKey = null, token = null}) => {
        if (otpUserKey) {
            setErrMsg(null);
            if (otpUserKey.length <= 0) {
                setErrMsg(t('LoginPage.LoginNoID'));
                return;
            }

            setOtpUserKey(otpUserKey);
            onLoginProcess(otpUserKey);
        } else if (token) {
            if (rememberMe) {
                storage.setLocal(SK.LOGIN_INFO, {masterClientKey, userKey, rememberMe});
            } else {
                storage.removeLocal(SK.LOGIN_INFO);
            }

            onOTPTokenSuccess({token});
        }
    }

    const onOTPTokenSuccess = async ({token}) => {
        api.setToken(token);
        await timeout(1000);
        api.getMe()
            .then(data => {
                ds.setLogin(true);
                storage.setSession(SK.LOGIN_USER_INFO, {data});
            })
            .catch(err => {
                setErrMsg(err.rtMsg);
            });
    }

    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    // 입력 폼(form)과 이미지&언어 변경 버튼을 제외한 영역을 클릭했을 때 폼이 사라지게 하기
    function handleOutsideClick(clickForm, clickBtn, clickLang) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (isNotPC || qrOpen || otpOpen || totpOpen) {
                    event.stopPropagation()
                } else if (clickBtn.current.contains(event.target) || clickLang.current.contains(event.target)) {
                    event.stopPropagation()
                } else if (!clickForm.current.contains(event.target)) {
                    setLoginShow(false)
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [clickForm, clickBtn, qrOpen, otpOpen, totpOpen]);
    }

    function MainForm(clickBtn, clickLang) {

        const clickForm = useRef(null);
        handleOutsideClick(clickForm, clickBtn, clickLang);

        const handleOnIdle = () => {
            setLoginShow(false);
        };

        useIdleTimer({
            timeout: 30000,
            onIdle: handleOnIdle,
            debounce: 500
        });

        return (
            <Box className={classes.leftPane} ref={clickForm}>
                <Box display='flex' className={classes.form}>
                    {isNotPC === true ? <LanguageSelector/> :
                        <IconButton
                            className={classes.arrow}
                            onClick={() => setLoginShow(false)}
                        >
                            <ArrowCloseIcon/>
                        </IconButton>
                    }
                    <Box display='flex' className={classes.logoImg}>
                        <LogoImage width={140} height={'auto'}/>
                    </Box>
                    <Typography className={classes.userName}>{t('LoginPage.UserName')}</Typography>
                    <TextField variant="outlined"
                               required
                               fullWidth
                               id="userKey"
                               name="userKey"
                               value={userKey}
                               disabled={isProcess}
                               onChange={onUserKeyChange}
                               onKeyPress={(e) => {
                                   e.key === 'Enter' && onLogin()
                               }}
                               color="primary"
                               placeholder={t('LoginPage.LoginPlaceholder')}
                               className={classes.inputField}
                               inputProps={{className: classes.inputText}}
                               InputProps={{
                                   startAdornment: (
                                       <InputAdornment position="start">
                                           <EmailIcon fontSize="small"/>
                                       </InputAdornment>
                                   ),
                                   classes: {
                                       adornedStart: classes.adornedStart,
                                       input: classes.placeholder
                                   }
                               }}
                    />
                    {countDown !== null ?
                        <Box className={classes.loginLabel}>
                            <FormControlLabel
                                control={<Checkbox icon={<UncheckedIcon className={classes.rememberIcon}/>}
                                                   checkedIcon={<CheckedIcon className={classes.rememberIcon}/>}
                                                   checked={rememberMe} onChange={onRememberMe}
                                                   color='primary'
                                                   disabled={isProcess}
                                />}
                                className={(rememberMe === true ? classes.rememberLabelChecked : classes.rememberLabel)}
                                label={t('LoginPage.LabelRememberMe')}
                            />
                            <Box className={classes.progressAlert}>
                                <Typography variant='body1' className={classes.description}>
                                    {`${t('LoginPage.TimeRemain')} : ${countDown} (${t('LoginPage.Sec')})`}
                                </Typography>
                                <Button
                                    size='small'
                                    variant="contained"
                                    color="primary"
                                    onClick={onCancel}
                                >{t('LoginPage.BtnCancel')}
                                </Button>
                            </Box>
                        </Box>
                        :
                        <FormControlLabel
                            control={<Checkbox checked={rememberMe}
                                               icon={<UncheckedIcon className={classes.rememberIcon}/>}
                                               checkedIcon={<CheckedIcon className={classes.rememberIcon}/>}
                                               onChange={onRememberMe} color='primary'
                                               disabled={isProcess}/>}
                            className={(rememberMe === true ? classes.rememberLabelChecked : classes.rememberLabel)}
                            label={t('LoginPage.LabelRememberMe')}
                        />
                    }

                    <Box>
                        <Button
                            fullWidth
                            size='large'
                            variant="contained"
                            color="primary"
                            disabled={isProcess}
                            onClick={isProcess ? onCancel : onLogin}
                            className={classes.loginBtn}
                        >{t('LoginPage.BtnLogin')}
                        </Button>
                        <Box className={classes.orLine}>
                            <hr className={classes.hrStyle}/>
                            <Typography className={classes.orText}>{t('LoginPage.Or')}</Typography>
                            <hr className={classes.hrStyle}/>
                        </Box>

                        <Box className={classes.subLoginBtnBox}>
                            {isNotPhone ?
                                <Button
                                    size='large'
                                    variant="contained"
                                    disabled={isProcess}
                                    onClick={isProcess ? onQrCancel : onQrLogin}
                                >
                                    <QrIcon className={classes.subLoginBtnIcon}/>
                                    <div className={classes.subLoginBtnLabel}>QR</div>
                                </Button>
                                : ''}
                            <QrPage open={qrOpen}
                                    onClose={onQrCancel}
                                    title={t('LoginPage.QrDialog.Title')}
                                    qrId={qrId.current}
                                    qrUrl={qrUrl.current}
                                    onProgressMsg={setProgressMsg}
                                    onErrMsg={setErrMsg}
                                    handedUserKey={userKey}
                            />
                            <Button
                                size='large'
                                variant="contained"
                                disabled={isProcess}
                                onClick={onOTPLogin}
                            >
                                <OtpIcon className={classes.subLoginBtnIcon}/>
                                <div className={classes.subLoginBtnLabel}>OTP</div>
                            </Button>
                            <OtpPage otpType={OtpType.DeviceOTP}
                                     handedUserKey={userKey}
                                     open={otpOpen}
                                     onClose={() => setOtpOpen(false)}
                                     onSuccess={onOTPSuccess}
                                     numInputs={6}
                            />
                            <Button
                                size='large'
                                variant="contained"
                                disabled={isProcess}
                                onClick={isProcess ? () => setTotpOpen(false) : () => setTotpOpen(true)}
                            >
                                <TotpIcon className={classes.subLoginBtnIcon}/>
                                <div className={classes.subLoginBtnLabel}>TOTP</div>
                            </Button>

                            <OtpPage otpType={OtpType.DeviceTOTP}
                                     handedUserKey={userKey}
                                     open={totpOpen}
                                     onClose={() => setTotpOpen(false)}
                                     onSuccess={onOTPTokenSuccess}
                                     numInputs={6}
                            />
                        </Box>
                    </Box>

                    <Box mt={3} hidden={!isError} className={classes.alert}>
                        <StatusAlert status={errMsg} onClose={onErrorHandler}/>
                    </Box>

                    <Box mt={3} hidden={!isProcess} className={classes.alert}>
                        <ProgressAlert status={progressMsg} count={countDown}/>
                    </Box>

                    <Box mt={3} hidden={isProcess || isError} className={classes.alert}/>

                    <Box mt={3}>
                        {/*<AppDownLink/>*/}
                    </Box>
                    <Box mt={2} alignSelf="flex-start">
                        <Copyright className={classes.copyright}/>
                    </Box>
                </Box>
            </Box>
        )
    }

    const isNotPhone = useMediaQuery(theme.breakpoints.up('sm'));
    const isNotPC = useMediaQuery(theme.breakpoints.down('md'));

    // 폼을 열렸을 때 배경변경 버튼이나 언어변경 버튼 눌러도 폼 숨기지 않음
    const clickBtn = useRef(null);
    const clickLang = useRef(null);


    return (
        <Container className={classes.wholeScreen} component="main">
            <Box className={classes.mainBg}>

                {/*    PC 화면 디자인     */}
                {isNotPC === false ?
                    <React.Fragment>
                        <ChangingBackground show={loginShow} clickBtn={clickBtn}/>
                        {loginShow ? '' :
                            (theme.type === 'gccs' ?
                                <img src={require('../../images/gccs/GCCS_logo_main.png')} className={classes.logoMain}
                                     alt={'Guardian CCS logo'}/>
                                : <LogoImage width={140} height={'auto'} className={classes.logoMain}/>)
                        }


                        <Box className={classes.topBtn}>
                            {loginShow ? '' :
                                <Button className={classes.loginShowBtn} onClick={() => setLoginShow(true)}>
                                    {t('LoginPage.BtnLogin')}
                                </Button>
                            }
                            <LanguageSelector clickLang={clickLang}/>
                        </Box>
                        <Slide direction='right' in={loginShow} timeout={{enter: 500, exit: 300}}>
                            {MainForm(clickBtn, clickLang)}
                        </Slide>
                    </React.Fragment>
                    :

                    // 태블릿 & 모바일 화면 디자인
                    <React.Fragment>
                        {isNotPhone && <Box className={classes.leftPaneBg}/>}
                        {MainForm()}
                    </React.Fragment>
                }
            </Box>
        </Container>
    );
}
